import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Donuts from '../../image/Group.png';
import DonutsTwo from '../../image/donuts.png';
import Shopify from '../../image/partnerLogo2.svg.png';
import AliExpress from '../../image/partnerLogo3.svg.png';
import Meta from '../../image/partnerLogo4.svg.png';
import Amazon from '../../image/partnerLogo5.svg.png';
import Ebay from '../../image/partnerLogo6.svg.png';
import OpenAi from '../../image/partnerLogo1.svg.png';
import WooCommerce from '../../image/image 57.png';
import VectorBg from '../../image/Vector bg(2).png';
import Triangle from '../../image/triangle.png';
import Facebook from '../../image/facebook.png';
import Tiktok from '../../image/tik tok.png';
import Pinterest from '../../image/pinterest.png';
import vectorBg from '../../image/sectionThree/Vector bg.png';
import isolationOne from '../../image/Vector (2).png';
import isolationTwo from '../../image/plan_2.svg.png';
import isolationThree from '../../image/plan_3.svg.png';
import Check from '../../image/green-check.svg.png';
import DonutsThree from '../../image/donutsTwo.png';
import Vortes from '../../image/vortes.png';
import TriangleTwo from '../../image/triangle(2).png';
import EllipseTwo from '../../image/Ellipse 16.png';
import EllipseThree from '../../image/Ellipse 14.png';
import EllipseFour from '../../image/Ellipse 13.png';
import EllipseFive from '../../image/Ellipse 12.png';
import TrustPilot from '../../image/trustpilot.svg.png';
import { Link } from 'react-router-dom';
import './Home.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import exagon from '../../image/exagon.png';
import Footer from '../Footer/Footer';
import productOne from '../../image/productResearch1.webp';
import productTwo from '../../image/productResearch2.webp';
import productThree from '../../image/productResearch6.webp';
import productFour from '../../image/productResearch4.webp';
import Login from '../Login/Login';
import SignUp from '../SignUp/SignUp';
import ForGotPassword from '../ForgotPassword/ForGotPassword';
import { ToastContainer } from 'react-toastify';
import Plans from '../Plans/Plans';
import {updatePlan } from '../Firebase/Firebase';
import PlanBanner from '../PlanBanner/PlanBanner';
import video from '../../image/intro.mp4';

import man1 from '../../image/reviews/19.jpg';
import woman1 from '../../image/reviews/10.jpg';
import man2 from '../../image/reviews/27 (1).jpg';
import woman2 from '../../image/reviews/6.jpg';
import man3 from '../../image/reviews/38 (1).jpg';
import woman3 from '../../image/reviews/24.jpg';
import man4 from '../../image/reviews/45.jpg';
import woman4 from '../../image/reviews/26.jpg';
import man5 from '../../image/reviews/55.jpg';
import woman5 from '../../image/reviews/38.jpg';
import man6 from '../../image/reviews/56.jpg';
import woman6 from '../../image/reviews/54.jpg';
import man7 from '../../image/reviews/57.jpg';
import woman7 from '../../image/reviews/60.jpg';
import man8 from '../../image/reviews/62.jpg';
import woman8 from '../../image/reviews/80.jpg';
import man9 from '../../image/reviews/67.jpg';
import woman9 from '../../image/reviews/80.jpg';
import man10 from '../../image/reviews/75.jpg';
import woman10 from '../../image/reviews/88.jpg';
import man11 from '../../image/reviews/83.jpg';
import gradientOne from '../../image/gradientone.png';
import gradientTwo from '../../image/gradienttwo.png';
import { analytics, logEvent}  from '../Firebase/Firebase.js';
import Widget from '../Widget/Widget.js';

const Home = ({user, plan, setPlan}) => {
const [active, setActive] = useState(1);
const [open, setOpen] = useState(false);
const [openSignUp, setOpenSignUp] = useState(false);
const [forgot, setForgot] = useState(false);
const [plans, setPlans] = useState(false);
const [openBanner, setOpenBanner] = useState(false);



const handleButtonClick = (buttonNumber) => {
  setActive(buttonNumber);
};


const handleOpenLogin = () => {
setForgot(false);
setOpenSignUp(false);
setOpen(true);
document.querySelector('main').style.filter = 'blur(10px)';
};

const handleOpenLoginClose = () => {
  setOpen(false);
  document.querySelector('main').style.filter = '';
};

const handleOpenLoginSign = () => {
  setOpen(false);
  setOpenSignUp(true);
  document.querySelector('main').style.filter = 'blur(10px)';
  };
  
const handleOpenLoginSignClose = () => {
  setOpenSignUp(false);
  document.querySelector('main').style.filter = '';
};


const handleOpenForgot = () => {
setOpen(false);
setForgot(true);
document.querySelector('main').style.filter = 'blur(10px)';
};

const handleCloseForgot = () => {
  setForgot(false);
  document.querySelector('main').style.filter = '';
};

const handleOpenPlans = () => {
  setPlans(true);
  document.querySelector('main').style.filter = 'blur(10px)';
  window.location.href = '#plans';
};

const handleClosePlans = () => {
setPlans(false);
document.querySelector('main').style.filter = '';
};

const handleCloseBanner = () => {
  setOpenBanner(false);
  document.querySelector('main').style.filter = '';
};




const location = useLocation();
const urlParams = new URLSearchParams(location.search);
const checkoutIdParams = urlParams.get('checkout_session_id');
const referralurl = urlParams.get('ref');
const [checkoutId, setCheckOutId] = useState(checkoutIdParams);
const [referral, setReferral] = useState(referralurl);
const [dark, setDark] = useState(false);

const darkMode = () => {
  setDark(!dark);
  console.log(dark);
  document.body.classList.toggle("dark");
}; 


const [shopData, setShopData] = useState();
const fetchShopData = async () => {
  try {
    const response = await fetch('https://backend-8rbz.onrender.com/shop-data', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
    },
     credentials: 'include', 
    });

    if (response.ok) {
      const data = await response.json();
      console.log('la sessione:', data);
      if (data.shopData && data.shopData.name) {
        setShopData(data.shopData);
      };
    } else {
      console.error('Errore durante la richiesta:', response);
    }
  } catch (error) {
    console.error('Errore generale durante la richiesta:', error);
  };
};


useEffect( () => {
  if(user) {
    console.log('home user:', user);
    console.log('checkout:', checkoutId )
    if (checkoutId !== '' && checkoutId !== undefined && checkoutId !== null) {
      const requestBody = { checkout_session_id: checkoutId };
      if (referral !== undefined && referral !== '') {
        requestBody.referral = referral;
    };
  
      fetch('https://backend-8rbz.onrender.com/get_plan_details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Plan details:', data);
        updatePlan(user.uid, data[0].productName,data[0].userEmail);
        setOpenBanner(true);
        document.querySelector('main').style.filter = 'blur(10px)';
        console.log('il piano fetch:', plan)
        setPlan(data[0].productName);
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      });
    };
     if(plan === 'free') {
        handleOpenPlans();
     };
  };
  fetchShopData();
}, [user,plan]);


useEffect(() => {
  logEvent(analytics, 'page_view');
 }, []);

const showBanner = checkoutId !== '';
  return (
    <>
    <Widget />
    <ToastContainer />
    {showBanner && <PlanBanner open={openBanner} handleClose={handleCloseBanner}/>}
    <ForGotPassword
      handleClose={handleCloseForgot}
      handleOpen={handleOpenLogin} 
      open={forgot}
      />
    <SignUp 
    handleOpenPlans={handleOpenPlans} 
    handleOpen={handleOpenLogin}  
    handleClose={handleOpenLoginSignClose} 
    open={openSignUp}
    />
    <Login 
    handleOpenForgot={handleOpenForgot} 
    handleOpen={handleOpenLoginSign} 
    handleClose={handleOpenLoginClose} 
    open={open}
    />
    <Plans handleClose={handleClosePlans} open={plans}/>
      <Navbar  
      handleOpen={handleOpenLogin} 
      user={user}
      darkMode={darkMode}
      dark={dark}
      />
      <main>
      <section>
        <div className='container-hero-home'>
        {dark && (
  <>
    <img className="gradient-one" src={gradientOne} alt="..." />
    <img className="gradient-two" src={gradientTwo} alt="..." />
  </>
)}
        <div className='container-hero-circle'>
         <div></div>
        </div>
          <img className='donuts' src={Donuts}  alt='...'/>
          <img className='donutsTwo' src={DonutsTwo}  alt='...'/>
          <div className='container-hero-text'>
          <h1>
            <div className='animation-container'>
            <span>Sell</span> 
            <span>Import</span>
            <span>Optimize</span>
            </div>
          <span>a product</span> 
          <span>in Shopify</span>
        </h1>
          </div>
          <div className='container-hero-content'>
          <video autoPlay muted loop>
<source src={video} type="video/mp4"/>
</video>
           {user && plan !== 'free' ? (
  <>
    {user && plan !== 'free' && shopData ? (
      <Link to='/import-product' className='cta'>Try it</Link>
    ) : (
      <Link className='cta' to='/connect'>Try it</Link>
    )}
  </>
) : (
  <button className='cta' onClick={handleOpenLogin}>Try it</button>
)}
           <p>TRUSTED & INTEGRATED WITH</p>
           <marquee className={dark ? 'dark' : ''}>
           <img src={Shopify} alt="..." />
           <img src={AliExpress} alt="..." />
           <img src={Meta} alt="..." />
           <img src={Amazon} alt="..." />
           <img src={Ebay} alt="..." />
           <img src={OpenAi} alt="..." />
           <img src={WooCommerce} alt="..." />
           </marquee>
          </div>
           <img src={Triangle} alt='...' className='triangle' />
           <img src={EllipseFour} className='ellipseOne' alt='...'/>
        </div>
      </section>
      <section className='discover'>
      {dark && (
  <>
    <img className="gradient-three" src={gradientOne} alt="..." />
    <img className="gradient-four" src={gradientTwo} alt="..." />
  </>
)}
        <div className='container'>
          <img className='vectorbg' src={VectorBg} alt='...' />
          <h3 style={{marginTop: '120px'}}>Looking for <span >the perfect product?</span></h3>
          <ul className="radar">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <div className='radar-imageOne'>
              <img src={productOne}  alt='....' />
              </div>
              <div className='radar-imageTwo'>
              <img src={productTwo}  alt='....' />
              </div>
              <div className='radar-imageThree'>
              <img src={productThree}  alt='....' />
              </div>
              <div className='radar-imageFour'>
              <img src={productFour}  alt='....' />
              </div>
          </ul>
          <h3 style={{fontSize: '52px', marginTop: '30px'}}>Launch Your <span >Next Bestseller in 3 Seconds</span></h3>
          <div className='socials'>
           <Link className='social' to=''><img src={Facebook} alt='...' /> facebook </Link>
           <Link className='social' to=''><img src={Tiktok} alt='...' /> tiktok</Link>
           <Link className='social' to=''><img src={Pinterest} alt='...' /> pinterest</Link>
          </div>
          <img src={EllipseFive}  alt='...' className='ellipseFive'/>
        </div>
      </section>
      <section>
        <div className='container'>
        <div className='container-four-circle'>
         <div></div>
        </div>
          <img className='bgvectortwo' src={vectorBg} alt='...' />
          <img className='donutsThree' src={DonutsThree} alt='...' />
          <img className='vortes' src={Vortes} alt='...' />
          <img  src={TriangleTwo} alt='...' className='triangleTwo' />
          <img  src={EllipseTwo} alt='...' className='ellipseTwo' />
          <img src={EllipseThree} alt='...' className='ellipseThree' />
          <h3 style={{marginTop: '100px'}}> Join <span>the crew</span></h3>
          <div className='select-price'>
            <button  className={active === 1 ? 'active' : ''}
        onClick={() => handleButtonClick(1)}>Monthly</button>
            <button  className={active === 2 ? 'active' : ''}
        onClick={() => handleButtonClick(2)}>Yearly <span>Save 20%</span></button>
          </div>
          <div className='cards'>
          <div className='card'>
              <div className='card-header'>
                <span><img src={isolationOne}  alt='...' /></span>
                <p>Free Plan</p>
                </div>
                <div className='card-content'>
    <h4>Free<span>/month</span></h4>
              <h4><img src={Check}  alt=''/><strong>1</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />1 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress Links</li>
               </ul>
               <div className='get-start'>
                  <button className='gt-button' onClick={handleOpenLogin}>Get Started</button>               
                <p>No commitment</p>
               </div>
            </div>
                </div>
            <div className='card'>
              <div className='card-header'>
              <span><img src={isolationOne}  alt='...' /></span>
              <p>Starter Plan</p>
            </div>
            <div className='card-content'>
            {active === 1 ? (
    <h4>$29<span>/month</span></h4>
) : (
    <h4>$288<span>12/month</span></h4>
)}
              <h4><img src={Check}  alt=''/><strong>50</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />1 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress Links</li>
               </ul>
               <div className='get-start'>
                {user ? ( 
                <Link to={active === 1 ? 'https://buy.stripe.com/cN229Z84J1JEbEk4gl' : 'https://buy.stripe.com/14k01RgBfagaeQw9AC'} className='gt-button'>Get Started</Link> 
                ) : (
                  <button className='gt-button' onClick={handleOpenLogin}>Get Started</button>               
)}
                <p>No commitment</p>
               </div>
            </div>
            </div>
            <div className='card pro-card'>
              <div className='card-header'>
              <span><img src={isolationTwo}  alt='...' /></span>
              <p>Pro Plan</p>
              </div>
              <div className='card-content'>
              {active === 1 ? (
    <h4>$59<span>/month</span></h4>
) : (
    <h4>$567<span>12/month</span></h4>
)}
              <h4><img src={Check}  alt=''/><strong>150</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />3 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress Links</li>
               </ul>
               <div className='get-start'>
                {user ? (
                    <Link to={active === 1 ? 'https://buy.stripe.com/00g15V1Gl2NI4bS148' : 'https://buy.stripe.com/00geWL4Sx2NIcIo9AB'} className='gt-button'>Get Started</Link>
                ) : (
                  <button className='gt-button' onClick={handleOpenLogin}>Get Started</button>               
                )}
                <p>No commitment</p>
               </div>
            </div>
              </div>
            <div className='card'>
              <div className='card-header'>
                <span><img src={isolationThree}  alt='...' /></span>
                <p>Gold Plan</p>
                </div>
                <div className='card-content'>
                {active === 1 ? (
    <h4>$89<span>/month</span></h4>
) : (
    <h4>$854<span>12/month</span></h4>
)}
              <h4><img src={Check}  alt=''/><strong>250</strong>Product Pages / month</h4>
               <ul>
                <li><img src={Check}  alt='...' />5 Shopify Store Linked</li>
                <li><img src={Check}  alt='...' />Create from Aliexpress Links</li>
               </ul>
               <div className='get-start'>
                {user ? (
                  <Link to={active === 1 ? 'https://buy.stripe.com/fZeaGvdp3dsmcIo7sv' : 'https://buy.stripe.com/3cs6qfckZaga23K8ww'} className='gt-button'>Get Started</Link>
                ) : (
                  <button className='gt-button' onClick={handleOpenLogin}>Get Started</button>               
                )}
                <p>No commitment</p>
               </div>
            </div>
                </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
        <div className='container-five-circle'>
         <div></div>
        </div>
          <img src={exagon} alt='...' className='exagon' />
          <h3 style={{marginTop: '100px'}}>Why we are <span>the best</span></h3>
          <img className='trustpilot' src={TrustPilot} alt='...' />
          <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]} 
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        <SwiperSlide><p>shopscribe has been a game-changer for my Shopify store. Importing products from AliExpress is now a breeze, and the SEO-friendly pages have boosted my visibility. Saved me hours of work!</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man1} alt='...' />Alex Johnson</p></div></SwiperSlide>
        <SwiperSlide><p>Incredible tool! shopscribe made product import and page creation so easy. The marketing angle it suggests is always on point. It's saved me so much time.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman1} alt='...' />Maria Sanchez</p></div></SwiperSlide>
        <SwiperSlide><p>Absolutely love shopscribe! The ease of importing products and creating SEO-optimized pages is unmatched. It's like having a virtual assistant.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man2} alt='...' />David Smith</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe has simplified my workflow immensely. Importing products and setting up shop is now quicker and more efficient. A true time-saver!</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman2} alt='...' />Linda Green</p></div></SwiperSlide>
        <SwiperSlide><p>I was struggling with time management until I discovered shopscribe. Now, adding new products and optimizing pages for SEO takes no time at all. Highly recommend!</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man3} alt='...' />John Carter</p></div></SwiperSlide>
        <SwiperSlide><p>This tool has transformed my business. With shopscribe, I can quickly import products and create engaging, SEO-optimized pages. It's incredibly efficient.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman3} alt='...' />Sophie Turner</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe is a lifesaver. It's streamlined my process for adding products from AliExpress and the SEO suggestions are spot on. Saved me countless hours.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man4} alt='...' />Mike Brown</p></div></SwiperSlide>
        <SwiperSlide><p>The efficiency of shopscribe is astounding. Importing products and creating marketable pages has never been easier or faster. It's a must-have for Shopify store owners.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman4} alt='...' />Emma Wilson</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe has been invaluable in saving time and improving my store's SEO. The ease of importing products and creating pages is fantastic.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man5} alt='...' />Chris Martin</p></div></SwiperSlide>
        <SwiperSlide><p>A game-changer for my online store. shopscribe has made product import and page creation effortless. The time saved is incredible.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman5} alt='...' />Olivia Scott</p></div></SwiperSlide>
        <SwiperSlide><p>The time shopscribe has saved me is immeasurable. Importing products and optimizing for SEO is now a quick, painless process.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man6} alt='...' />Ethan Moore</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe is revolutionary. It's streamlined my entire process for adding products from AliExpress. The SEO benefits are a huge plus.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman6} alt='...' />Hannah Lee</p></div></SwiperSlide>
        <SwiperSlide><p>I'm amazed by the time savings with shopscribe. Importing and marketing products on my Shopify store has never been easier.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man7} alt='...' />Daniel Young</p></div></SwiperSlide>
        <SwiperSlide><p>The simplicity and efficiency of shopscribe are unmatched. It's significantly reduced the time I spend on product pages.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman7} alt='...' />Jessica Thompson</p></div></SwiperSlide>
        <SwiperSlide><p>Thanks to shopscribe, I can now quickly import products and focus on marketing. It's been a massive time saver for my Shopify store.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man8} alt='...' />Jason Walker</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe has made a huge difference in how I manage my store. Quick product imports and SEO-friendly pages have saved me so much time.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman8} alt='...' />Chloe Kim</p></div></SwiperSlide>
        <SwiperSlide><p>Incredible how much time shopscribe has saved me. Importing products is fast, and the SEO optimization helps my store stand out.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man9} alt='...' />Ryan Garcia</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe is a must for any Shopify store. The time saved on product import and SEO optimization is invaluable.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman9} alt='...' />Samantha Miller</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe has drastically cut down the time I spend on my store. The product import feature and SEO tips are amazing.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man10} alt='...' />James Wilson</p></div></SwiperSlide>
        <SwiperSlide><p>A real time-saver! shopscribe has made importing products and optimizing pages for SEO so easy. I'm very impressed.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={woman10} alt='...' />Lauren Davis</p></div></SwiperSlide>
        <SwiperSlide><p>shopscribe is a game-changer. It's saved me countless hours on product importation and SEO optimization. Highly recommend it to any Shopify store owner.</p><div className='user'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><p><img src={man11} alt='...' />Benjamin Clark</p></div></SwiperSlide>
      </Swiper>
        </div>
      </section>
      </main>
      <Footer />
    </>
  )
}

export default Home;
